"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductAttributeTablePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var tabel_cell_1 = require("../../../../../components/tabel_cell");
var table_bottom_content_1 = require("../../../../../components/table_bottom_content");
var table_top_bar_1 = require("../../../../../components/table_top_bar");
var local_storage_names_1 = require("../../../../../constants/local_storage_names");
var pages_1 = require("../../../../../constants/pages");
var routes_1 = require("../../../../../constants/routes");
var useMemoizedCallback_1 = require("../../../../../helpers/useMemoizedCallback");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var branch_1 = require("../../../../../reducers/slices/branch/branch");
var main_1 = require("../../../../../reducers/slices/main");
var product_attribute_1 = require("../../../../../reducers/slices/products/product_attribute/product_attribute");
var delete_product_attribute_thunk_1 = require("../../../../../reducers/slices/products/product_attribute/thunks/delete_product_attribute_thunk");
var get_product_attributes_thunk_1 = require("../../../../../reducers/slices/products/product_attribute/thunks/get_product_attributes_thunk");
var constants_1 = require("./constants");
var ProductAttributeTablePage = function () {
    var _a, _b;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), search = _c[0], setSearch = _c[1];
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.productAttributeState; }), pagination = _d.pagination, productAttributes = _d.productAttributes, loading = _d.loading;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _e.client_token, user = _e.user;
    var pageTitle = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).pageTitle;
    var _f = (0, react_1.useState)(1), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = (0, react_1.useState)(10), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var _h = (0, react_1.useState)(new Set([])), selectedKeys = _h[0], setSelectedKeys = _h[1];
    var _j = (0, react_1.useState)({
        column: "createdAt",
        direction: "ascending",
    }), sortDescriptor = _j[0], setSortDescriptor = _j[1];
    var tableColumnConfig = localStorage.getItem(local_storage_names_1.TABLE_COLUMN_CONFIG);
    var storedVisibleColumns = (_a = JSON.parse(tableColumnConfig)) !== null && _a !== void 0 ? _a : {};
    var initialVisibleColumns = (_b = storedVisibleColumns["product_attributes"]) !== null && _b !== void 0 ? _b : constants_1.INITIAL_VISIBLE_COLUMNS;
    var _k = (0, react_1.useState)(new Set(initialVisibleColumns)), visibleColumns = _k[0], setVisibleColumns = _k[1];
    var handleSetColumns = function (value) {
        storedVisibleColumns["product_attributes"] = Array.from(value);
        localStorage.setItem(local_storage_names_1.TABLE_COLUMN_CONFIG, JSON.stringify(storedVisibleColumns));
        setVisibleColumns(value);
    };
    var headerColumns = (0, react_1.useMemo)(function () {
        if (visibleColumns === "all")
            return constants_1.columns;
        return constants_1.columns
            .map(function (item) {
            if (item.uid === sortDescriptor.column) {
                return __assign(__assign({}, item), { sortDirection: sortDescriptor.direction });
            }
            return item;
        })
            .filter(function (column) { return Array.from(visibleColumns).includes(column.uid); });
    }, [visibleColumns, sortDescriptor]);
    var onSelectionChange = (0, useMemoizedCallback_1.useMemoizedCallback)(function (keys) {
        if (keys === "all") {
            setSelectedKeys(keys);
            return;
        }
        var resultKeys = new Set(keys);
        var currentKeys = selectedKeys === "all" ? new Set(productAttributes.map(function (item) { return String(item._id); })) : selectedKeys;
        currentKeys.forEach(function (key) {
            if (!productAttributes.some(function (item) { return String(item._id) === key; })) {
                resultKeys.add(key);
            }
        });
        setSelectedKeys(resultKeys);
    });
    var filterSelectedKeys = (0, react_1.useMemo)(function () {
        var resultKeys = new Set();
        resultKeys = selectedKeys;
        return resultKeys;
    }, [selectedKeys, productAttributes]);
    var getProductAttributes = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_product_attributes_thunk_1.get_product_attributes_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getProductAttributes(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (search === "") {
            setRowsPerPage(rowsPerPage);
            return getProductAttributes(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getProductAttributes(__assign(__assign({}, pagination), { page: page, search_string: search }));
        }
    };
    var onSearchChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (e === "")
                        setCurrentPage(1);
                    setSearch(e);
                    return [4 /*yield*/, dispatch((0, branch_1.setTablePagination)(pages_1.initialPaginationValues))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var renderCellViews = function (cellValue) { return []; };
    var rowActions = function (cellValue) { return [
        {
            key: "view",
            onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_ATTRIBUTES_DETAILS_ROUTE, "/").concat(cellValue._id)); },
        },
        {
            key: "edit",
            onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_ATTRIBUTES_DETAILS_ROUTE, "/").concat(cellValue._id)); },
        },
        {
            key: "delete",
            onClick: function () {
                return dispatch((0, main_1.setContentModal)({
                    title: "Confirm deletion of ".concat(cellValue === null || cellValue === void 0 ? void 0 : cellValue.name, " product attribute"),
                    content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col justify-start pt-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this product attribute?", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 pt-4 justify-start" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "danger", variant: "light", onPress: function () { return dispatch((0, main_1.setContentModal)(null)); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onPress: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, dispatch((0, delete_product_attribute_thunk_1.delete_product_attribute_thunk)({
                                                            _id: cellValue === null || cellValue === void 0 ? void 0 : cellValue._id,
                                                        }))];
                                                    case 1:
                                                        _a.sent();
                                                        dispatch((0, main_1.setContentModal)(null));
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); } }, { children: "Continue" }))] }))] }))),
                }));
            },
        },
    ]; };
    (0, react_1.useEffect)(function () {
        if (!!client_token) {
            console.log("client_token", client_token);
            dispatch((0, get_product_attributes_thunk_1.get_product_attributes_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "mb-4 relative rounded-large" }, { children: [(0, table_top_bar_1.tableTopBar)({
                pageTitle: pageTitle,
                recordCount: pagination.countTotal,
                actionButtons: [
                    {
                        label: "Add Product Attribute",
                        iconClassname: "solar:add-circle-bold",
                        onClick: function () {
                            dispatch((0, product_attribute_1.setSelectedProductAttribute)(null));
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_ATTRIBUTES_DETAILS_ROUTE));
                        },
                    },
                ].filter(Boolean),
            }), (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ isHeaderSticky: true, "aria-label": "Product Attributes", bottomContent: (0, table_bottom_content_1.tableBottomContent)({
                    filterSelectedKeys: filterSelectedKeys,
                    page: currentPage,
                    pages: Math.round(pagination.countTotal / rowsPerPage),
                    filteredItems: productAttributes,
                    onPreviousPage: function () { return onPageChange(currentPage - 1); },
                    onNextPage: function () { return onPageChange(currentPage + 1); },
                    setPage: onPageChange,
                }), bottomContentPlacement: "outside", classNames: {
                    td: "before:bg-transparent",
                }, selectedKeys: filterSelectedKeys, selectionMode: "multiple", sortDescriptor: sortDescriptor, topContentPlacement: "outside", onSelectionChange: onSelectionChange }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_2.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_2.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: loading, emptyContent: "This table is empty.", items: productAttributes, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) {
                            return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                        } }))] }))] })));
};
exports.ProductAttributeTablePage = ProductAttributeTablePage;
