"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationConfig = exports.initialProductAttributeFamilyValues = exports.columns = exports.INITIAL_VISIBLE_COLUMNS = void 0;
exports.INITIAL_VISIBLE_COLUMNS = [
    "name",
    "description",
    "attributes",
    "actions"
];
exports.columns = [
    { name: "Name", uid: "name" },
    { name: "Description", uid: "description" },
    { name: "Attributes", uid: "attributes" },
    { name: "Actions", uid: "actions" }
];
exports.initialProductAttributeFamilyValues = {
    name: "",
    description: "",
    attributes: [],
};
exports.validationConfig = {
    name: {
        required: true,
        minLength: 2,
        maxLength: 50,
    },
    type: {
        required: true,
    },
    options: {
        required: true,
    },
    default_value: {
        required: false,
    },
    attributes: {
        required: true,
    },
};
